import React, { ReactElement, useRef } from 'react';
import * as Styled from './styles';
import './style.css';
import { TwoColSection } from '../../components/TwoColSection/TwoColSection';
import { useVisibilityDetection } from '../../hooks/useVisibilityDetection';
import { css } from '@emotion/react';
import { PRICING_DATA_LTD } from "../../data/pricing";
import { useLocation } from '@reach/router';
interface VersionProps {
  FBExclusive: boolean;
  NewBox: boolean;
}

export const SketchWowVersionsSection = ({ FBExclusive, NewBox }: VersionProps): ReactElement => {
  const accordionRef = useRef(null);
  const { isVisible } = useVisibilityDetection(accordionRef);
  const pricing = {PRICING_DATA_LTD}.PRICING_DATA_LTD
  const location = useLocation();
  return (
    <Styled.SafariWrapper>
      <Styled.Wrapper isVisible={isVisible} ref={accordionRef}>

        <div className="row">
          {(location.pathname != '/' && location.pathname != '') && 
            <h1 id="title">Go Lock-In Your Lifetime Deal On SketchWow Now!</h1>
          }

          {(location.pathname == '/' || location.pathname == '') && 
            <h1 id="title">Go Try SketchWow Now!</h1>
          }

          <div className="column-1">
            { /*!NewBox &&
              <img id="lifetime-deal-img1" src="/sketch-wow-box.webp" alt="sketch-wow-box" width="100%" />
            }
            {NewBox &&
              <img id="lifetime-deal-img1" src="/SketchWow-Promotional-Box.png" alt="sketch-wow-box" width="80%"/>
            */ }
            <img id="lifetime-deal-img1" src="/SketchWow-Promotional-Box.png" alt="sketch-wow-box" width="80%"/>
          </div>

            {FBExclusive &&
              <div className="column-2">
                <img id="lifetime-deal-img2" src="/fb-special-deal.png" alt="SketchWow" width="280px"/>
                
                {(location.pathname != '/' && location.pathname != '') && 
                  <div>
                    <div>
                      <span  id="regular-price"  
                      
                        css={css`font-size: 15px;`}
                      >Regular Price: </span>

                      <strong id="price-crossout"
                      css={css`font-size: 15px; `}
                      >$468/YEAR</strong>
                    </div>

                    <h1 id="one-timepayment" css={css`color:#50B3F4 !important; font-size: 26px; `}><strong>NOW ONLY: {pricing[0].std}</strong></h1>
                    <h1 >1-TIME PAYMENT</h1>
                    <h5 id="no-fees" css={css``}>ZERO MONTHLY / ANNUAL FEES FOREVER</h5>
                  </div>
                }
              </div>
            }

            
            {!FBExclusive &&
            <div className="column-2" >
               
              <div  css={(location.pathname == '/' || location.pathname == '') ? css`display:none;` : css``}>
                <span  id="regular-price"  
                
                css={css`font-size: 15px;`}
              >Regular Price: </span>

              <strong id="price-crossout"
               css={css`font-size: 15px; `}
              >$468/YEAR</strong>
              

              <h1 id="one-timepayment" css={css`color:#50B3F4 !important; font-size: 26px; `}><strong>NOW ONLY: {pricing[0].std}</strong></h1>
              <h1 >1-TIME PAYMENT</h1>
              <h5 id="no-fees" css={css``}>ZERO MONTHLY / ANNUAL FEES FOREVER</h5>

              </div>
               
 

                
             
               {/*
              <img id="lifetime-deal-img2" src="/lifetimedeal.png" alt="SketchWow" width="180px"/>
              <h4 id="regular-price">REGULAR PRICE:</h4>
                <h1 id="price-crossout">$468/YEAR</h1>
                <h1 id="now-only">NOW ONLY: {pricing[0].std}</h1>
                <h1 id="one-timepayment">1-TIME PAYMENT</h1>
            <h5 id="no-fees">($0 monthly/yearly fees. Forever)</h5>*/}
              </div>
            }


          <div className="column-3" css={(location.pathname == '/' || location.pathname == '') ? css`@media only screen and (min-width: 920px){width:49% !important;}` : css``}>
            {/*NewBox &&
              <img id="30-day-img1" src="/image@2x.png" alt="sketch-wow-box" width="100%" />
          */}
             <Styled.Content>
               <h3>30-Day Money-Back Guarantee</h3>
               <p>
                 Go take SketchWow for a spin. You've got a full month to make up your mind. We think you'll fall in love with how simple &
                 fun it is to create amazing, engaging sketches.
               </p>
               <p>
                 If for some reason you are not completely "Wow'd" by SketchWow within the first 30 days, simply email us at
                 support@sketchwow.com. You'll get a prompt and courteous refund. We want you to be happy using SketchWow.
               </p>
             </Styled.Content>
          </div>
        </div>

      </Styled.Wrapper>
    </Styled.SafariWrapper>
  );
};

